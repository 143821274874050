import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_ROOT, BASIC_AUTH } from '../api-config';

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = { games: [], users : [], selectedId : 0 };
  }

  componentDidMount() {
    this.fetchUsers()
  }

  deleteGamesPlayed(id) {
    axios.delete(API_ROOT + "/users/"+id+"/games", { auth: BASIC_AUTH })
        .then(res => {
          this.fetchGames(id);
        });
  }

  fetchGames(id) {
    axios.get(API_ROOT + "/users/"+id+"/games",{ auth: BASIC_AUTH })
        .then(res => {
          const games = res.data;
          this.setState({ games: games })
        })
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          console.log("USERS:")
          console.log(users)
          if(users.length > 0) {
            this.setState({ selectedId: users[0]["id"] });
            this.fetchGames(users[0]["id"]);
          }
          this.setState({ users: users })
        })
  }

  handleUserChange(event) {
    console.log(event.target.value);
    this.setState({ selectedId: event.target.value });
    this.fetchGames(event.target.value)
  }

  deleteGamesPlayedHandler(event) {
    const userId = this.state.selectedId
    this.deleteGamesPlayed(userId)
  }


  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };
    return (
        <div>
          <h3>Get games played for user</h3>
          <div className="row">
            <form className="col s6">
              <h5>Select user:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleUserChange.bind(this)} className="browser-default">
                  {this.state.users.map((user, index) =>
                      <option key={index} value={user['id']}>
                        {user.name} ( { user.phoneNumber } )
                      </option>
                  )}
                </select>
              </div>
            </form>
          </div>
          <div><span style={linkStyle} onClick={this.deleteGamesPlayedHandler.bind(this)}>Delete all games played for user</span></div>
          <div className="row">
            <h4>Games played for user</h4>
            <table>
              <thead>
              <tr>
                <th>Game ID</th>
                <th>Won type</th>
                <th>Played status</th>
                <th>Played date</th>
              </tr>
              </thead>

              <tbody>
              {this.state.games.map((game, index) =>
                  <tr key={index} data-id={index}>
                    <td>{game["campaignId"]}</td>
                    <td>{game["wonType"]}</td>
                    <td>{ game["playStatus"] }</td>
                    <td>{ game["date"] }</td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
    );
  }
}


export default Games;


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_ROOT, BASIC_AUTH } from '../api-config';

class QRCodeGame extends Component {
  constructor(props) {
    super(props);
    this.state = { games : [], selecctedId : 0, codes : [] };
  }

  componentDidMount() {
    this.fetchGames();
  }

  fetchGames() {
    axios.get(`${API_ROOT}/games/qrcode`, { auth: BASIC_AUTH })
        .then(res => {
          const games = res.data.games;
          this.setState({ games: games });

          if(games.length > 0) {
            this.setState({ selecctedId: games[0]["gameId"] });
            this.fetchCodes(games[0]["gameId"]);
          }
        });
  }

  handleGameChange(event) {
    console.log(event.target.value);

    this.setState({ selecctedId: event.target.value });
    this.fetchCodes(event.target.value);
  }

  fetchCodes(selectedId) {
    console.log(`${API_ROOT}/games/qrcode/${selectedId}`)
    axios.get(`${API_ROOT}/games/qrcode/${selectedId}`, { auth: BASIC_AUTH })
        .then(res => {
          const codes = res.data.codes;
          this.setState({ codes: codes });
        }).catch(err => {
          this.setState({ codes: [] });
      })
  }

  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };

    return (
        <div>
          <h3>QR Codes</h3>
          <h5>Select game campaign</h5>
          <div className="input-field col s12">
            <select onChange={this.handleGameChange.bind(this)} className="browser-default">
              {this.state.games.map((game, index) =>
                  <option key={index} value={game['gameId']}>
                    {game.title} ( { game.id } )
                  </option>
              )}
            </select>
          </div>
          <table>
            <thead>
            <tr>
              <th>Dynamic link</th>
              <th>QR code link</th>
              <th>QR code</th>
            </tr>
            </thead>

            <tbody>
            {this.state.codes.map((code, index) =>
                <tr key={index} data-id={index}>
                  <td>{code.dynamicLink}</td>
                  <td>{code.link}</td>
                  <td><img src={code.link}/> </td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
    );
  }
}


export default QRCodeGame;


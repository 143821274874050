import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { API_ROOT, BASIC_AUTH } from '../api-config';

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = { trophies: [], users : [], selectedId: 0 };
  }

  componentDidMount() {
    this.fetchUsers()
  }

  fetchCoupons(id) {
    axios.get(API_ROOT + "/user/"+id+"/trophies", { auth: BASIC_AUTH })
        .then(res => {
          const trophies = res.data.trophies;
          console.log(trophies)

          this.setState({ trophies: trophies, selectedId: id })
        });
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          console.log(users)
          if(users.length > 0) {
            this.fetchCoupons(users[0]["id"])
          }
          this.setState({ users: users })
        })
  }

  handleUserChange(event) {
    console.log(event.target.value);
    this.fetchCoupons(event.target.value)
  }

  deleteTrophyHandler(event) {
    const userId = this.state.selectedId
    this.deleteTrophies(userId)
  }

  deleteTrophies(id) {
    axios.delete(API_ROOT + "/user/"+id+"/trophies", { auth: BASIC_AUTH })
        .then(res => {
          this.fetchCoupons(id);
        });
  }


  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };
    
    return (
        <div>
          <h3>Get trophies for user</h3>
          <div className="row">
            <form className="col s6">
              <h5>Select user:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleUserChange.bind(this)} className="browser-default">
                  {this.state.users.map((user, index) =>
                      <option key={index} value={user['id']}>
                        {user.name} ( { user.phoneNumber } )
                      </option>
                  )}
                </select>
              </div>
            </form>
          </div>
          <div className="row">
            <h4>Trophies for user</h4>
            <div><span style={linkStyle} onClick={this.deleteTrophyHandler.bind(this)}>Delete all trophies for user</span></div>
            <table>
              <thead>
              <tr>
                <th>trophyId</th>
                <th>title</th>
                <th>userId</th>
                <th>count</th>
                <th>seen</th>
                <th>completed</th>
              </tr>
              </thead>

              <tbody>
              {this.state.trophies.map((trophy, index) =>
                  <tr key={index} data-id={index}>
                    <td>{ trophy['trophyId'] }</td>
                    <td>{ trophy['title'] }</td>
                    <td>{ trophy.userId }</td>
                    <td>{ trophy.count }</td>
                    <td>{ trophy.seen }</td>
                    <td>{ trophy.completed }</td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
    );
  }
}


export default Progress;


import React, { Component } from 'react';
import { Link } from 'react-router-dom'

class Header extends Component {
  renderButtons() {
    return (
      <div>
      </div>
    );
  }

  render() {
    return (
      <nav>
        <div className="nav-wrapper">
          <ul className="right">
            <li><Link to="/storebox-test">Make purchase</Link></li>
            <li><Link to="/storebox-test/receipts">Receipts</Link></li>
            <li><Link to="/storebox-test/users">Users</Link></li>
            <li><Link to="/storebox-test/add-progress">Add trophy</Link></li>
            <li><Link to="/storebox-test/trophies">Trophies</Link></li>
            <li><Link to="/storebox-test/vouchers">Vouchers</Link></li>
            <li><Link to="/storebox-test/games-played">Games</Link></li>
            <li><Link to="/storebox-test/play-game">Play game</Link></li>
            <li><Link to="/storebox-test/target-groups">Target group</Link></li>
            <li><Link to="/storebox-test/qr-code-game">QR codes</Link></li>
          </ul>
        </div>
      </nav>
    );
  }
}

export default Header

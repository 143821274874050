import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_ROOT, BASIC_AUTH } from '../api-config';

class Receipts extends Component {
  constructor(props) {
    super(props);
    this.state = { users : [], selectedMemberId : "", receipts : [] };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          this.setState({ users: users });

          if(users.length > 0) {
            this.setState({ selectedMemberId: users[0]["storeboxId"] });
            this.fetchReceipts(users[0]["storeboxId"]);
          }
        });
  }

  handleUserChange(event) {
    console.log(event.target.value);

    this.setState({ selectedMemberId: event.target.value });
    this.fetchReceipts(event.target.value);
  }

  fetchReceipts(selectedMemberId) {
    console.log(`${API_ROOT}/receipts/${selectedMemberId}`)
    axios.get(`${API_ROOT}/receipts/${selectedMemberId}`, { auth: BASIC_AUTH })
        .then(res => {
          const receipts = res.data.receipts;
          this.setState({ receipts: receipts });
        }).catch(err => {
      this.setState({ receipts: [] });
    })
  }

  deleteReceiptHandler(e) {
    if (window.confirm('Are you sure you want to delete the receipt?')) {
      const receipt = this.state.receipts[e.currentTarget.dataset.id];
      axios.delete(`${API_ROOT}/receipts/${this.state.selectedMemberId}/${receipt['receiptId']}`, { auth: BASIC_AUTH })
          .then(res => {
            this.fetchReceipts(this.state.selectedMemberId);
          })
    } else {
      console.log("NOOOO")
    }
  }

  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };

    return (
        <div>
          <h3>Receipts</h3>
          <h5>Select user</h5>
          <div className="input-field col s12">
            <select onChange={this.handleUserChange.bind(this)} className="browser-default">
              {this.state.users.map((user, index) =>
                  <option key={index} value={user['storeboxId']}>
                    {user.name} ( { user.phoneNumber } )
                  </option>
              )}
            </select>
          </div>
          <table>
            <thead>
            <tr>
              <th>Id</th>
              <th>Store</th>
              <th>Date</th>
              <th>Total</th>
              <th>Delete</th>
            </tr>
            </thead>

            <tbody>
            {this.state.receipts.map((receipt, index) =>
                <tr key={index} data-id={index}>
                  <td>{receipt.receiptId}</td>
                  <td>{receipt.storeName}</td>
                  <td>{ receipt.purchaseDate }</td>
                  <td>{ receipt.grandTotal }</td>
                  <td data-id={index} onClick={this.deleteReceiptHandler.bind(this)}><span style={linkStyle}>Delete</span></td>
                </tr>
            )}
            </tbody>
          </table>

        </div>
    );
  }
}


export default Receipts;


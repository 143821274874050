import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import NotFound from './components/NotFound';
import Header from './components/Header';
import Dashboard from './components/Dashboard';
import Users from './components/Users';
import Progress from './components/Progress';
import AddProgress from './components/AddProgress';
import Trophies from './components/Trophies';
import Games from './components/Games';
import Vouchers from './components/Voucher';
import PlayGame from './components/PlayGame';
import Receipts from './components/Receipts';
import TargetGroups from './components/TargetGroups';
import QRCodeGame from './components/QRCodeGame';

class App extends Component {
  constructor(props){
    super(props)
  }

  componentDidMount() {
    const basicAuthUsername = sessionStorage.getItem("basicAuthUsername");
    const basicAuthPassword = sessionStorage.getItem("basicAuthPassword");

    if(basicAuthUsername && basicAuthPassword) {
      console.log("HAVE BASIC AUTH")
    } else {
      while(true) {
        let basicAuthUsernamePrompt = prompt("Enter username", "");
        if(basicAuthUsernamePrompt) {
          sessionStorage.setItem("basicAuthUsername", basicAuthUsernamePrompt);
          break;
        }
      }

      while(true) {
        let basicAuthPasswordPrompt = prompt("Enter password", "");
        if(basicAuthPasswordPrompt) {
          sessionStorage.setItem("basicAuthPassword", basicAuthPasswordPrompt);
          break;
        }
      }

      window.location.reload();
    }
  }

  render () {
    return <div>
      <div className="container">
        <Header />
        <Switch>
          <Route path="/storebox-test/users" component={Users}/>
          <Route path="/storebox-test/progress" component={Progress}/>
          <Route path="/storebox-test/add-progress" component={AddProgress}/>
          <Route path="/storebox-test/trophies" component={Trophies}/>
          <Route path="/storebox-test/games-played" component={Games}/>
          <Route path="/storebox-test/vouchers" component={Vouchers}/>
          <Route path="/storebox-test/play-game" component={PlayGame}/>
          <Route path="/storebox-test/receipts" component={Receipts}/>
          <Route path="/storebox-test/target-groups" component={TargetGroups}/>
          <Route path="/storebox-test/qr-code-game" component={QRCodeGame}/>


          <Route path="/" component={Dashboard}/>
        </Switch>
      </div>
    </div>
  }
}

export default App;
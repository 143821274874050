import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_ROOT, BASIC_AUTH } from '../api-config';

class AddProgress extends Component {
  constructor(props) {
    super(props);
    this.state = { trophies: [], users : [], selectedUserId : 0, selectedRuleId : 0, numberOfItems : 1, concurrent : 1 };
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchRules();
  }

  onSubmit(event) {
    event.preventDefault();
    
    const user = this.state.users[this.state.selectedUserId];
    const trophy = this.state.trophies[this.state.selectedRuleId];

    const obj = {
      userId : user['id'],
      trophyId : trophy['id'],
      numberOfItems : this.state.numberOfItems,
      concurrent : this.state.concurrent
    };
    
    console.log(obj)

    axios.post(`${API_ROOT}/trophy/progress`,obj, {  auth: BASIC_AUTH })
        .then(res => {
          alert("Progress added")
        });
  }

  fetchRules() {
    axios.get(`${API_ROOT}/trophies`, { auth: BASIC_AUTH })
        .then(res => {
          const trophies = res.data.trophies;
          console.log(trophies)
          this.setState({ trophies: trophies })
        })
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          console.log(users)
          this.setState({ users: users })
        })
  }

  handleUserChange(event) {
    console.log(event.target.value);
    this.setState({selectedUserId : event.target.value});
  }

  handleRuleChange(event) {
    console.log(event.target.value);
    this.setState({selectedRuleId : event.target.value});
  }


  render() {
    return (
        <div>
          <h3>Add trophy progress for user</h3>
          <div className="row">
            <form className="col s6" onSubmit={this.onSubmit.bind(this)}>
              <div className="input-field">
                <input
                    placeholder="Number of items"
                    type="number"
                    value={this.state.numberOfItems}
                    onChange={e => this.setState({ numberOfItems: e.target.value})}
                />
              </div>
              <div className="input-field">
                <input
                    placeholder="Number of concurrent executions"
                    type="number"
                    value={this.state.concurrent}
                    onChange={e => this.setState({ concurrent: e.target.value})}
                />
              </div>
              <h5>Select user:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleUserChange.bind(this)} className="browser-default">
                  {this.state.users.map((user, index) =>
                      <option key={index} value={index}>
                        {user.name} ( { user.phoneNumber } )
                      </option>
                  )}
                </select>
              </div>
              <h5>Select rule:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleRuleChange.bind(this)} className="browser-default">
                  {this.state.trophies.map((trophy, index) =>
                      <option key={index} value={index}>
                        {trophy.title}
                      </option>
                  )}
                </select>
              </div>
              <button className="btn">Add trophy progress</button>
            </form>
          </div>
        </div>
    );
  }
}


export default AddProgress;


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { API_ROOT, BASIC_AUTH } from '../api-config';

class Vouchers extends Component {
  constructor(props) {
    super(props);
    this.state = { vouchers: [], users : [], selectedId : 0 };
  }

  componentDidMount() {
    this.fetchUsers()
  }

  deleteVouchers(id) {
    axios.delete(API_ROOT + "/users/"+id+"/vouchers", { auth: BASIC_AUTH })
        .then(res => {
          this.fetchVouchers(id);
        });
  }

  fetchVouchers(id) {
    axios.get(API_ROOT + "/users/"+id+"/vouchers", { auth: BASIC_AUTH })
        .then(res => {
          const vouchers = res.data;
          this.setState({ vouchers: vouchers })
        })
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          console.log("USERS:")
          console.log(users)
          if(users.length > 0) {
            this.setState({ selectedId: users[0]["id"] });
            this.fetchVouchers(users[0]["id"]);
          }
          this.setState({ users: users })
        })
  }

  handleUserChange(event) {
    console.log(event.target.value);
    this.setState({ selectedId: event.target.value });
    this.fetchVouchers(event.target.value)
  }

  deleteVouchersHandler(event) {
    const userId = this.state.selectedId;
    this.deleteVouchers(userId)
  }


  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };
    return (
        <div>
          <h3>Get vouchers for user</h3>
          <div className="row">
            <form className="col s6">
              <h5>Select user:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleUserChange.bind(this)} className="browser-default">
                  {this.state.users.map((user, index) =>
                      <option key={index} value={user['id']}>
                        {user.name} ( { user.phoneNumber } )
                      </option>
                  )}
                </select>
              </div>
            </form>
          </div>
          <div><span style={linkStyle} onClick={this.deleteVouchersHandler.bind(this)}>Delete all vouchers for user</span></div>
          <div className="row">
            <h4>Vouchers for user</h4>
            <table>
              <thead>
              <tr>
                <th>ID</th>
                <th>Expires</th>
                <th>Received</th>
                <th>Redeemed</th>
                <th>Campaign type</th>
                <th>Campaign ID</th>
                <th>Reward</th>
              </tr>
              </thead>

              <tbody>
              {this.state.vouchers.map((voucher, index) =>
                  <tr key={index} data-id={index}>
                    <td>{voucher["id"]}</td>
                    <td>{voucher["expiresDate"]}</td>
                    <td>{ voucher["receivedDate"] }</td>
                    <td>{ voucher["redeemedDate"] }</td>
                    <td>{ voucher["campaignType"] }</td>
                    <td>{ voucher["campaignId"] }</td>
                    <td>{ voucher["rewardId"] }</td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
    );
  }
}


export default Vouchers;


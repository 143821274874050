import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { API_ROOT, BASIC_AUTH } from '../api-config';

class Users extends Component {
  constructor(props) {
    super(props);
    this.state = { users : [] };
  }

  componentDidMount() {
    this.fetchUsers();
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          console.log(users)

          this.setState({ users: users })
        })
  }

  deleteBEHandler(e) {
    if (window.confirm('Are you sure you want to delete the user?')) {
      const user = this.state.users[e.currentTarget.dataset.id];
      axios.delete(`${API_ROOT}/user/adapt/`+user.id, { auth: BASIC_AUTH })
          .then(res => {
            this.fetchUsers()
          })
    } else {
      console.log("NOOOO")
    }
  }

  deleteBESBHandler(e) {
    if (window.confirm('Are you sure you want to delete the user?')) {
      const user = this.state.users[e.currentTarget.dataset.id];

      axios.delete(`${API_ROOT}/user/both/`+user.id, { auth: BASIC_AUTH })
          .then(res => {
            this.fetchUsers()
          })
    } else {
      console.log("NOOOO")
    }
  }

  startSoftDeleteJob(e) {
    axios.post(`${API_ROOT}/user/soft-delete`, {}, { auth: BASIC_AUTH })
      .then(res => {
        this.fetchUsers()
      })
  }

  startSoftDeleteJobBirthdayRequired(e) {
    axios.post(`${API_ROOT}/user/soft-delete`, {}, { auth: BASIC_AUTH })
      .then(res => {
        this.fetchUsers()
      })
  }

  startSoftDeleteJobBirthdayBelowAgeLimit(e) {
    console.log(BASIC_AUTH)
    axios.post(`${API_ROOT}/user/soft-delete/below-age-limit`, {}, { auth: BASIC_AUTH })
      .then(res => {
        this.fetchUsers()
      })
  }

  startDeleteSoftDeletedUsersJob(event) {
    axios.delete(`${API_ROOT}/user/soft-delete`, { auth: BASIC_AUTH })
      .then(res => {
        this.fetchUsers()
      })
  }

  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };

    return (
        <div>
          <h3>Users</h3>
          <div className="row">
            <button className="btn" onClick={this.startSoftDeleteJobBirthdayRequired.bind(this)}>Start set users soft deleted job - Invalid birthday</button>
          </div>
          <div className="row">
            <button className="btn" onClick={this.startSoftDeleteJobBirthdayBelowAgeLimit.bind(this)}>Start set users soft deleted job - Birthday below age limit</button>
          </div>
          <div className="row">
            <button className="btn" onClick={this.startDeleteSoftDeletedUsersJob.bind(this)}>Start delete soft deleted users job</button>
          </div>
          <table>
            <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Phone number</th>
              <th>Delete from Adapt BE</th>
              <th>Delete from Adapt BE + SB</th>
            </tr>
            </thead>

            <tbody>
            {this.state.users.map((user, index) =>
                <tr key={index} data-id={index}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{ user.phoneNumber }</td>
                  <td data-id={index} onClick={this.deleteBEHandler.bind(this)}><span style={linkStyle}>Delete from Adapt BE</span></td>
                  <td data-id={index} onClick={this.deleteBESBHandler.bind(this)}><span style={linkStyle}>Delete from Adapt BE + SB</span></td>
                </tr>
            )}
            </tbody>
          </table>

        </div>
    );
  }
}


export default Users;


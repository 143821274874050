import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { API_ROOT, BASIC_AUTH } from '../api-config';

class TargetGroups extends Component {
  constructor(props) {
    super(props);
    this.state = { targetGroups : [], selectedTargetGroupId : 0, users : [] };
  }

  componentDidMount() {
    this.fetchTargetGroups();
  }

  fetchTargetGroups() {
    axios.get(`${API_ROOT}/targetgroups`, { auth: BASIC_AUTH })
        .then(res => {
          const groups = res.data.groups;
          this.setState({ targetGroups: groups });

          if(groups.length > 0) {
            this.setState({ selectedTargetGroupId: groups[0]["id"] });
            this.fetchUsers(groups[0]["id"]);
          }
        });
  }

  handleUserChange(event) {
    console.log(event.target.value);

    this.setState({ selectedTargetGroupId: event.target.value });
    this.fetchUsers(event.target.value);
  }

  fetchUsers(selectedId) {
    console.log(`${API_ROOT}/targetgroups/${selectedId}`)
    axios.get(`${API_ROOT}/targetgroups/${selectedId}`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data.users;
          this.setState({ users: users });
        }).catch(err => {
          this.setState({ users: [] });
      })
  }

  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };

    return (
        <div>
          <h3>Target groups</h3>
          <h5>Select target group</h5>
          <div className="input-field col s12">
            <select onChange={this.handleUserChange.bind(this)} className="browser-default">
              {this.state.targetGroups.map((group, index) =>
                  <option key={index} value={group['id']}>
                    {group.name} ( { group.id } )
                  </option>
              )}
            </select>
          </div>
          <table>
            <thead>
            <tr>
              <th>Id</th>
              <th>Name</th>
              <th>Email</th>
              <th>Gender</th>
              <th>Birthday</th>
            </tr>
            </thead>

            <tbody>
            {this.state.users.map((user, index) =>
                <tr key={index} data-id={index}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{ user.email }</td>
                  <td>{ user.gender }</td>
                  <td>{ user.birthday }</td>
                </tr>
            )}
            </tbody>
          </table>
        </div>
    );
  }
}


export default TargetGroups;


import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';

import { API_ROOT, BASIC_AUTH } from '../api-config';

class Progress extends Component {
  constructor(props) {
    super(props);
    this.state = { progress: [], users : [] };
  }

  componentDidMount() {
    this.fetchUsers()
  }

  fetchProgress(id) {
    axios.get(`${API_ROOT}/user/progress/`+id, { auth: BASIC_AUTH })
        .then(res => {
          const progress = res.data.progress;
          console.log(progress)

          this.setState({ progress: progress })
        });
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          console.log(users)
          if(users.length > 0) {
            this.fetchProgress(users[0]["storebox_id"])
          }
          this.setState({ users: users })
        })
  }

  handleUserChange(event) {
    console.log(event.target.value);
    this.fetchProgress(event.target.value)
  }


  render() {
    return (
        <div>
          <h3>Get progress for user</h3>
          <div className="row">
            <form className="col s6">
              <h5>Select user:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleUserChange.bind(this)} className="browser-default">
                  {this.state.users.map((user, index) =>
                      <option key={index} value={user['storebox_id']}>
                        {user.name} ( { user.phone_number } )
                      </option>
                  )}
                </select>
              </div>
            </form>
          </div>
          <div className="row">
            <h4>Progress for user</h4>
            <table>
              <thead>
              <tr>
                <th>templateId</th>
                <th>campaignCode</th>
                <th>campaignName</th>
                <th>progress</th>
                <th>progressGoal</th>
              </tr>
              </thead>

              <tbody>
              {this.state.progress.map((progress, index) =>
                  <tr key={index} data-id={index}>
                    <td>{progress.templateId}</td>
                    <td>{progress.campaignCode}</td>
                    <td>{ progress.campaignName }</td>
                    <td>{ progress.progress }</td>
                    <td>{ progress.progressGoal }</td>
                  </tr>
              )}
              </tbody>
            </table>
          </div>
        </div>
    );
  }
}


export default Progress;


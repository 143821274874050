import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_ROOT, BASIC_AUTH } from '../api-config';

class Games extends Component {
  constructor(props) {
    super(props);
    this.state = { games: [], users : [], selectedId : 0, selectedGameId : 0, selectedType: "won" };
  }

  componentDidMount() {
    this.fetchUsers();
    this.fetchGamess();
  }

  fetchGamess() {
    axios.get(API_ROOT + "/games", { auth: BASIC_AUTH })
        .then(res => {
          const games = res.data;
          if(games.length > 0) {
            this.setState({ selectedGameId: games[0]["id"] });
          }
          this.setState({ games: games })
        })
  }

  fetchUsers() {
    axios.get(`${API_ROOT}/user`, { auth: BASIC_AUTH })
        .then(res => {
          const users = res.data;
          console.log("USERS:")
          console.log(users)
          if(users.length > 0) {
            this.setState({ selectedId: users[0]["id"] });
          }
          this.setState({ users: users })
        })
  }

  playGame() {
    axios.post(API_ROOT + "/games",{
      user : this.state.selectedId,
      game : this.state.selectedGameId,
      type : this.state.selectedType
    }, { auth: BASIC_AUTH }).then(res => {
      console.log(res)
    })
  }

  handleUserChange(event) {
    console.log(event.target.value);
    this.setState({ selectedId: event.target.value });
  }

  handleGameChange(event) {
    console.log(event.target.value);
    this.setState({ selectedGameId: event.target.value });
  }

  handleTypeChange(event) {
    console.log(event.target.value);
    this.setState({ selectedType: event.target.value });
  }

  playGameHandler(event) {
    const userId = this.state.selectedId;
    this.playGame();
  }


  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };
    return (
        <div>
          <h3>Start game played for user</h3>
          <div className="row">
            <form className="col s6">
              <h5>Select user:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleUserChange.bind(this)} className="browser-default">
                  {this.state.users.map((user, index) =>
                      <option key={index} value={user['id']}>
                        {user.name} ( { user.phoneNumber } )
                      </option>
                  )}
                </select>
              </div>
              <h5>Select game:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleGameChange.bind(this)} className="browser-default">
                  {this.state.games.map((game, index) =>
                      <option key={index} value={game['id']}>
                        {game.id} ( { game['gameType'] } )
                      </option>
                  )}
                </select>
              </div>
              <h5>Select game played status:</h5>
              <div className="input-field col s12">
                <select onChange={this.handleTypeChange.bind(this)} className="browser-default">
                    <option value="won">Won</option>
                    <option value="lost">Lost</option>
                    <option value="won_no_price">Won no price</option>
                </select>
              </div>
            </form>
          </div>
          <div><span style={linkStyle} onClick={this.playGameHandler.bind(this)}>Start game play</span></div>
        </div>
    );
  }
}


export default Games;


let backendHost;

const hostname = window && window.location && window.location.hostname;
console.log(`[API-CONFIG] ${hostname}`);

if(hostname.includes("stage")) {
  backendHost = 'https://europe-west1-normal-stage.cloudfunctions.net/storeboxTestApp';
} else if(hostname.includes('localhost')) {
  backendHost = 'http://localhost:4005'
} else {
  backendHost = `https://dev-normal.mobileupdate.dk`;
}
console.log(`[API-CONFIG] ${backendHost}`);
export const API_ROOT = `${backendHost}/api/v1/storebox-test`;


const basicAuthUsername = sessionStorage.getItem("basicAuthUsername");
const basicAuthPassword = sessionStorage.getItem("basicAuthPassword");
console.log(`[API-CONFIG] basicAuthUsername: ${basicAuthUsername}`);

export const BASIC_AUTH = {
  username: basicAuthUsername,
  password: basicAuthPassword
}
import React, { Component } from 'react';

class NotFound extends Component {
  render(){
    return(
        <div>
          <p>Sorry, the page could not be found</p>
          <b>404 Not found</b>/
        </div>
    )
  }
}

export default NotFound
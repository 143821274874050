import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { API_ROOT, BASIC_AUTH } from '../api-config';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = { orderStore : {}, currentProductId: '', currentName : '', currentCategoryId: '', currentCount : '', currentPrice : '', currentVATRate: '', items : [], userId : '', users : [], stores: [] };
  }

  componentDidMount() {
    axios.get(`${API_ROOT}/user`, {
      auth: BASIC_AUTH
    })
    .then(res => {
      const users = res.data;
      if(users.length > 0) {
        this.setState({userId : users[0]['storeboxId']});
      }
      this.setState({ users: users })
    })

    axios.get(`${API_ROOT}/stores`, {
      auth: BASIC_AUTH
    })
    .then(res => {
      const storesObj = res.data;
      let stores = storesObj.dk;

      let countryCheck = sessionStorage.getItem("basicAuthUsername").replace("adapt","");
      if(countryCheck.length > 0 && storesObj[countryCheck]) {
        stores = storesObj[countryCheck];
      }

      if(stores.length > 0) {
        this.setState({orderStore : stores[0]});
      }
      this.setState({ stores: stores })
    })
  }

  handleUserChange(event) {
    console.log(event.target.value);

    this.setState({ userId: event.target.value })
  }

  handleStoreChange(event) {
    console.log(event.target.value);

    this.setState({ orderStore: this.state.stores[event.target.value] })
  }

  onSubmitAddItem(event) {
    event.preventDefault();
    if(this.state.currentProductId && this.state.currentCategoryId && this.state.currentCount && this.state.currentPrice && this.state.currentName && this.state.currentVATRate) {
      var obj = {
        "productId" : this.state.currentProductId,
        "categoryId" : this.state.currentCategoryId,
        "count" : this.state.currentCount,
        "price" : this.state.currentPrice,
        "name" : this.state.currentName,
        "vatRate" : this.state.currentVATRate
      };

      var items = this.state.items;
      items.push(obj);

      this.setState({ items: items })
    } else {
      alert("Please enter all the needed data")
    }
  }

  onSubmit(event) {
    event.preventDefault();
    var items = this.state.items;
    var storeName = this.state.orderStore.name;
    var storeId = this.state.orderStore.storeId;

    if(items.length == 0 || !storeName || !storeId) {
      alert("Please add minimum one order item and define the store name and store id")
    } else {
      axios.post(`${API_ROOT}/buy`,{
        userId : this.state.userId,
        items: items,
        storeName: storeName,
        storeId : storeId
      }, {
        auth: BASIC_AUTH
      }).then(result => {
        if(result.data.success) {
          alert("Purchase was made")
        } else {
          alert("We could not create purchase")
        }
        console.log(result)
      }).catch(err => {
        alert("We got an error: " + err.message);
        console.log(err)
      });
    }
  }

  deleteItem(event) {
    var items = this.state.items;
    items.splice(event.currentTarget.dataset.id, 1);

    this.setState({ items: items })
  }

  render() {
    var linkStyle = {
      "textDecoration" : "underline",
      cursor: "pointer"
    };

    return (
        <div>
          <h3>Make purchase</h3>
          <h5>Select user:</h5>
          <div className="input-field col s12">
            <select onChange={this.handleUserChange.bind(this)} className="browser-default">
              {this.state.users.map((user, index) =>
                  <option key={index} value={user['storeboxId']}>
                    {user.name} ( { user.phoneNumber } )
                  </option>
              )}
            </select>
          </div>
          <h5>Select store:</h5>
          <div className="input-field col s12">
            <select onChange={this.handleStoreChange.bind(this)} className="browser-default">
              {this.state.stores.map((store, index) =>
                  <option key={index} value={index}>
                    {store.name} ( { store.storeId } )
                  </option>
              )}
            </select>
          </div>
          <h5>Add order item:</h5>
          <div className="row">Category ID's which trigger trophy: CLEANING, CONFECTS, HAIR CARE, HEALTHFOOD, MAKEUP, ORAL CARE, PERFUME, PET FOOD, SHAVING, SKINCARE, TEX DETERG, TRAVEL</div>
          <div className="row">
            <form onSubmit={this.onSubmitAddItem.bind(this)} className="col s6">
              <div className="input-field">
                <input
                    placeholder="Product ID"
                    value={this.state.currentProductId}
                    onChange={e => this.setState({ currentProductId: e.target.value })}
                />
              </div>
              <div className="input-field">
                <input
                    placeholder="Category ID"
                    type="text"
                    value={this.state.currentCategoryId}
                    onChange={e => this.setState({ currentCategoryId: e.target.value})}
                />
              </div>
              <div className="input-field">
                <input
                    placeholder="Number of items"
                    type="number"
                    value={this.state.currentCount}
                    onChange={e => this.setState({ currentCount: e.target.value})}
                />
              </div>
              <div className="input-field">
                <input
                    placeholder="Item price (including VAT)"
                    type="number"
                    value={this.state.currentPrice}
                    onChange={e => this.setState({ currentPrice: e.target.value})}
                />
              </div>
              <div className="input-field">
                <input
                    placeholder="Item VAT rate percentage, ex. 0,25"
                    type="number"
                    value={this.state.currentVATRate}
                    onChange={e => this.setState({ currentVATRate: e.target.value})}
                />
              </div>
              <div className="input-field">
                <input
                    placeholder="Item name"
                    type="text"
                    value={this.state.currentName}
                    onChange={e => this.setState({ currentName: e.target.value})}
                />
              </div>
              <button className="btn">Add</button>
            </form>
            <div className="col s6">
              <h5>Order items</h5>
              <table>
                <thead>
                <tr>
                  <th>ProductId</th>
                  <th>CategoryId</th>
                  <th>Count</th>
                  <th>Price</th>
                  <th>VAT</th>
                  <th>Name</th>
                  <th>Delete</th>
                </tr>
                </thead>
                <tbody>
                {this.state.items.map((item, index) =>
                    <tr key={index} data-id={index}>
                      <td>{item.productId}</td>
                      <td>{item.categoryId}</td>
                      <td>{ item.count }</td>
                      <td>{ item.price }</td>
                      <td>{ item.vatRate }</td>
                      <td>{ item.name }</td>
                      <td data-id={index} onClick={this.deleteItem.bind(this)}><span style={linkStyle}>Delete</span></td>
                    </tr>
                )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <form onSubmit={this.onSubmit.bind(this)} className="col s12">
              <button className="btn">Create purchase</button>
            </form>
          </div>
        </div>
    );
  }
}


export default Dashboard;

